<template>
 <div class="s_user_cash_out_list_pop">
    <el-dialog :title="seeStatus == 1 ? '提现审核': '查看详情'" class="s-task-dialog" top="30px" width="1100px" :close-on-click-modal="false" :visible.sync="show">
        <el-form :model="formData" label-width="140px" size="mini">
          <el-tabs v-model="activeName" type="card">
            <!-- 商家信息 -->
            <el-tab-pane label="商家店铺信息" name="1">
              <el-row>
                  <el-col :span="8">
                    <el-form-item label="商家店铺ID:">
                      <span>{{ formData.user_shop_id }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="商家店铺名称:">
                      <span>{{formData.userShop.title}}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="商家店铺类型:">
                      <span>{{ formData.userShop.platform_id | filtersPlatName(shopTypeList) }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="平台账号:">
                      <span>{{formData.userShop.account }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="发货人电话:">
                      <span>{{ formData.userShop.mobile  }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                    <el-form-item label="发货联系人:">
                      <span>{{formData.userShop.name }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="店铺图片:">
                      <el-image fit="cover" style="width: 100px; height: 100px" :src="formData.userShop.logo" :preview-src-list="[formData.userShop.logo]"> </el-image>
                    </el-form-item>
                  </el-col>
                    <el-col :span="8">
                    <el-form-item label="发货地址:">
                      <span>{{formData.userShop.region }} - {{formData.userShop.address }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="操作人ID:">
                      <span>{{ formData.operator_id }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="创建时间:">
                      <span>{{formatDate(formData.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="到期时间:">
                      <span>{{formatDate(formData.end_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
            </el-tab-pane>
            <!-- 买家信息 -->
            <el-tab-pane label="买家信息" name="2" class="s-tabs-content-two">
               <el-row>
                  <el-col :span="8">
                    <el-form-item label="买家ID:">
                      <span>{{ formData.user_id }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                      <el-form-item label="买家姓名:">
                        <span>{{formData.user.name }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="买家手机号:">
                        <span>{{formData.user.mobile }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="买家地址:">
                        <span>{{ formData.user.region }}{{ formData.user.address }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="买家qq:">
                        <span>{{formData.user.qq}}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="买家微信:">
                        <span>{{formData.user.wechat}}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="买家身份证号:">
                        <span>{{formData.user.card_id }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 只有淘宝 京东 xinyu_img 需要展示信誉等级和截图 -->
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="买家身份证正面照:">
                        <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.user.card_front" :preview-src-list="[formData.user.card_front]"></el-image>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="买家身份证反面照:">
                        <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.user.card_back" :preview-src-list="[formData.user.card_back]"></el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
            </el-tab-pane>
          </el-tabs>

          <el-row class="s-user-remark">
            <el-col :span="18">
              <el-form-item label="加入黑名单原因:">
                  <el-input placeholder="请输入加入黑名单原因" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">

            <el-button @click="show = false" v-show="seeStatus === 2">取 消</el-button>
            <el-button type="primary" @click="show = false" v-show="seeStatus === 2">确定</el-button>
        </div>
    </el-dialog>

 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
export default {
  props: {
    shopTypeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      seeStatus: 1, //1 审核 2查看
      query: {
        page:1, limit: 15, user_id: ''
      },

      table:{ loading:false, total:0, rows:[] },

      formData: {
        id: '',
        img: [],
        remark: '',
        userShop: { account : '' },
        platform: { title: '' },
        platformPlay: { title: '' },
        goods: [],
        task_type: { title: '' },
        search_keyword: [],
        entrance_pic: [],
        xdsj: { title: '' },
        huobi: { title: '' },
        liulan: { title: '' },
        main_liulan: [{ title: '' }],
        explain_img: [],
        xinyu: { title: '' },
        shop_limit: 0,
        account_limit: 0,
        close_time: 0,
        time_interval: 0,
        time_val: 0,
        user: {}
      },
      show: false,
      activeName: '1',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
    }
  },
  filters: {
    filtersPlatName(id, list) {
      let obj = list.find(item => item.id == id)
      if(obj) return obj.title
      return ''
    },
    filtersCreditPay(pay) {
      if(pay == 1) return '已开通'
      return '未开通'
    },
  },
  methods: {
    formatDate,
    // status //1 审核 2查看
    onShow(data, status) {
      console.log(status)
      this.seeStatus = status
      console.log(this.formData)
      this.formData = data
      this.show = true
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
.s_user_cash_out_list_pop {
  .el-dialog {
    max-height: 920px;
    margin: 0 auto;
  }
  .s-tabs-content-two {
    padding-bottom: 20px;
  }
  .s-user-cash-ou-list-pop-table {
    height: 500px;
    overflow: auto;
  }
  .s-user-remark {
    margin-top: 10px;
  }
}
</style>